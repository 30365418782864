<script lang="ts" setup>
import Cookies from 'js-cookie'
import { DOMAIN } from '../index'

const cookieName = 'cookie-accepted'
const isAccepted = ref(Cookies.get(cookieName) === 'true')

function hide() {
  Cookies.set(cookieName, 'true', { domain: DOMAIN, expires: 365 })
  isAccepted.value = true
}
</script>

<template>
  <q-dialog :model-value="!isAccepted" persistent position="bottom" @hide="hide">
    <q-card class="q-pa-sm">
      <q-card-section class="q-pb-none">
        {{ $t('other.cookie') }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="More info"
          color="accent"
          target="_blank"
          type="a"
          href="https://policies.google.com/technologies/cookies"
        />
        <q-btn v-close-popup flat label="Accept" color="accent" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
